import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

const ngMaterialModules: any[] = [MatButtonModule, MatIconModule];

@NgModule({
  imports: [CommonModule, RouterModule, FlexLayoutModule, ...ngMaterialModules],
  exports: [RouterModule, FlexLayoutModule, ...ngMaterialModules]
})
export class SharedModule {}
