import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(public swUpdate: SwUpdate) {}
  ngOnInit() {
    console.log('version 0.0.3');
    this.swUpdate.available.subscribe((event) => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
      if (event.available) {
        window.location.reload();
      }
    });
  }
}
